/**
 * @description: Request result set
 */
export enum ResultEnum {
  SUCCESS = 200,
  ERROR = 503,
  SYS_ERROR = 500,
  INVALID_TOKEN = 600,
  INVALID_MERCHANT = 601,
  LOGIN_FAILED = 603,
  LOGIN_FAILED_WITH_INITIAL_PASSWORD = 604,
  LOGIN_FAILED_WITH_EXPIRED_PASSWORD = 605,
  LOCKED_ACCOUNT = 606,
  API_NO_PERMISSION = 401,
  TIMEOUT = 400,
  TYPE = 'success',
}

/**
 * @description: request method
 */
export enum RequestEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

/**
 * @description:  contentType
 */
export enum ContentTypeEnum {
  // json
  JSON = 'application/json;charset=UTF-8',
  // form-data qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  upload
  FORM_DATA = 'multipart/form-data;charset=UTF-8',
}
